
.ocr-container {

  .input {
    border: 1px solid gray;
    background: white;
    padding: 0.5rem;
    border-radius: 0.25rem;
    width: 100%;
    color: #1e89f6;
    margin-right: 0.5rem;
  }
  
  .cropper-area {
    width: 70%;
  }
  
  .preview-area {
    width: 25%;
    margin-left: 1rem;
  }
  
  .crop-placeholder {
    width: 100%;
    height: 200px;
    background: #ccc;
  }
  
  .cropped-image img {
    max-width: 100%;
  }
  
  .button-area {
    padding: 1rem;
  }
  
  textarea {
    width: 50rem;
    height: 10rem;
    resize: none;
  }

}
